.App{
    overflow: hidden;
    background-image: url('../../Assets/background.png');
    
}

.directory-header{
    width: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
      width: auto;
      height: 13vh;
      padding: 2vh 0;
}


.photo{
    width: auto;
    height: 100%;
    left: -170px;
    top: 226px;
    object-fit: cover;
}

.search{
    display: flex;
    margin-top: 10px;
    height: 50%;
    border-radius: 50px;
    position: relative;
    justify-content: center;
    margin-bottom: 4vh;
    margin-top: 5vh;
}

input[type="text"] {
    width: 45%;
    padding: 12px 20px;
    margin-right: -50px;
    border: 2px solid #ccc;
    border-radius: 16px;
}

.searchBar{
    width: auto;
    height: 100%;
    font-size: 20px;
    font-family: 'Quicksand';
    border-radius: 10px;
    padding: 10px 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin: 20px 0 0px 20px;
    margin: 0;
}

.searchBtn{
    width: auto;
    height: auto;
    background-color: #D68D2E;
    font-family: 'Quicksand';
    border: 0px solid white;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: large;
    color: white;
}
.searchBtn:hover{
    background-color: #ca4949;
    color: white;
}
.filter-btn-wrapper{
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding-top: 3vw;
    padding-bottom: 3vw;
    padding-left: 2vw;
}
.filter-btn{
    width: 20vw;
    height: auto;
    font-weight: bold;
    font-size: 2vw;
    border: 1px solid #747474;
    border-radius: 12px;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
    color: black;
    background: white;
    margin-left: 1vw;
    margin-right: 3vw;
    padding-top: 3px;
    padding-bottom: 3px;
}
.filter-btn.active{
   background: #ca4949;
   color: white;
}

.filter-btn:hover{
    background: #ca4949;
    color: white;
}

.filter-img{
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: -50px;
    width: 7vw;
    height: auto;
}
.card-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10vh;
    margin-right: 6vw;
    margin-left: 1.5vw;

}
.col-3{
    transition: 0.3s;
    background: #F5F5F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
    width: 100% !important;
    height: 100%;
    padding: 1% 3%;
    margin: 4vh 2vw;
}

.col-3:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.headerimage{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6vh;
    margin-bottom: 4vh;
}
.card-image{
    height: 10vh;
    width: auto;
}
.title{
    margin-left: 2vw;
    width: 80%;
}

  
.timepic{
    padding: 7px 50px;
    padding-left: 2px;
    margin-left: 0.5vw;
}

.contactpic{
    /* padding: 7px 50px; */
    padding-left: 2px;
    margin-left: 0.5vw;
}
.title h3{
    font-size: 2.7vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* h3{
    font-family: 'Quicksand';
    padding-left: 18px;
    margin-left: 50px;
    margin-top: 0px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    justify-content: center;
    font-size: 30px;
} */


p{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
    padding-left: 15px;
    margin-right: -45px;
    margin-left: 25px;
    color: #747474;
    font-style: normal;
    line-height: 30px;
}

.row1{
    display: inline-block;
    margin-top: -15px;
    margin-left: 10px;
    margin-right: -35px;
    font-size: medium;
}


.redirect{
    text-decoration: none;
    width: 95px;
    height: auto;
    margin-left: 1vw;
    background-color: #D68D2E;
    font-family: 'Quicksand';
    border: 0px solid white;
    border-radius: 10px;
    padding: 10px 10px;
    font-size: large;
    color: white;
    justify-content: center;
}

.redirect:hover{
    background-color: #ca4949;
}

.card-body{
    margin-bottom: -20px;
    margin-left: 75px;
    padding: 5px;
}
@media all  and (max-width: 301px){
   
    .title{
        margin-left: 0vw;
        width: 100%;
    }
    .timepic{
        margin-left: 0vw;
    }
    .contactpic{
        margin-left: 0vw;
    }
    .redirect{
        margin-left: 0vw;
    }
}
@media all  and (min-width: 301px) and (max-width: 380px){
    p{
        margin: 0;
        padding: 0;
    }
    .title{
        margin-left: 6vw;
    }
    .timepic{
        margin-left: 6vw;
        margin-right: -1vw;
    }
    .contactpic{
        margin-left: 6vw;
    }
    .redirect{
        margin-left: 3vw;
    }
    .filter-btn-wrapper{
        margin-left: 2vw;
    }
    .info-contact{
        padding-left: -5vw;
        font-size: 8px !important;
    }
}
@media all  and (min-width: 381px) and (max-width: 500px){
    p{
        padding: 0;
    }
    .title{
        margin-left: 10vw;
    }
    .timepic{
        margin-left: 3vw;
    }
    .contactpic{
        margin-left: 3vw;
    }
    .redirect{
        margin-left: 8vw;
    }
    .filter-btn-wrapper{
        margin-left: 2vw;
    }
}
@media all  and (min-width: 501px) and (max-width: 1000px){
    p{
        margin: 0;
        padding: 0;
    }
    .title h3{
        font-size: 4vh;
    }
    .title{
        margin-left: 20vw;
        width: 40%;
    }
    .timepic{
        margin-left: 20vw;
    }
    .contactpic{
        margin-left: 20vw;
    }
    .redirect{
        margin-left: 17vw;
    }
    .filter-btn-wrapper{
        margin-left: 2vw;
    }
}
@media all and (min-width: 1001px) and (max-width: 1300px){
    
    p{
        padding: 0;
        font-size: 3vw;
    }
    .headerimage img{
        height: 15vh;
        width: auto;
    }
    .title h3{
        font-size: 6vh;
        width: 60%;
    }
    .title{
        margin-left: 19vw;
    }
    .timepic{
        margin-left: 10vw;
    }
    .contactpic{
        margin-left: 10vw;
    }
    .redirect{
        margin-left: 19vw;
    }
    
 }


@media all and (max-width: 300px){
    .row1{
        width: fit-content;
        padding-right: 8vw;
    }
    .card-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 7vw;
        grid-gap: 0vh;
    }
    .filter-btn-wrapper{
        margin-left: 2vw;
    }
}
@media all  and (min-width: 301px) and (max-width: 600px){
    
    .row1{
        width: fit-content;
        padding-right: 7vw;
    }
    .card-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 8vw;
        grid-gap: 3vh;
    
    }
    
    
 }

 @media all  and (max-width: 400px){
    .col-3{
    
        height: auto;
        width: 80vw !important;
        height: 60vh;
        padding: 5px;
        margin-bottom: 8vh;
    }
 }
 @media all and (min-width: 401px) and (max-width: 600px){
    .col-3{
        width: 80vw !important;
        height: 65vh;
        padding: 5px;
        margin-bottom: 0vh;
    }
 }
 
 @media all and (min-width: 601px) and (max-width: 1000px){
    
    .col-3{
    
        height: auto;
        width: 70vw !important;
        height: 63vh;
        padding: 5px;
        margin-bottom: 10vh;
        margin-left: 14vw;
    }
    .row1{
        width: fit-content;
        margin-right: 2vw;
    }
    .card-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 2vw;
        grid-gap: 0vh;
    }
 }

 @media all and (min-width: 1001px) and (max-width: 1300px){
    
    .col-3{
        width: 70vw !important;
        height: 70vh;
        padding: 5px;
        margin-bottom: 10vh;
        margin-left: 14vw;
    }
    .row1{
        width: fit-content;
        margin-right: 2vw;
    }
    .card-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 2vw;
    
    }
    
 }


@media all  and (max-width: 800px){
    .filter-btn{
        width: 90vw;
        height: auto;
        font-size: 5vw;
        margin-bottom: 2vh;
        margin-left: 0vw;
        margin-right: 0vw;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .filter-img{
        padding-left: 2vw;
        padding-right: 2vw;
        margin-left: -50px;
        width: 15vw;
        height: auto;
    }
    
 }
 @media all and (min-width: 801px) and (max-width: 1000px){
    .filter-btn{
        width: 95vw;
        height: auto;
        font-size: 5vw;
        margin-bottom: 2vh;
        margin-left: 0vw;
        margin-right: 0vw;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .filter-img{
        padding-left: 2vw;
        padding-right: 2vw;
        margin-left: -50px;
        width: 10vw;
        height: auto;
    }
    
 }
 @media all and (min-width: 1001px) and (max-width: 1300px){
    .filter-btn{
        width: 40vw;
        height: auto;
        font-size: 5vw;
        margin-bottom: 5vh;
        margin-left: 5vw;
        margin-right: 0vw;
        padding-top: 2vh;
        padding-bottom: 0px;
    }
    .filter-img{
        padding-left: 2vw;
        padding-right: 2vw;
        margin-left: -50px;
        width: 10vw;
        height: auto;
    }
}
@media all  and (max-width: 500px){
    .lifestyle{
        width: 20vw;
        height: auto;
        font-size: 2vw;
        margin-right: 5vw;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .electronic{
        width: 20vw;
        height: auto;
        font-weight: bold;
        font-size: 2vw;
        border: 1px solid #747474;
        border-radius: 12px;
        box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
        margin-right: 3vw;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .food{
        width: 20vw;
        height: auto;
        font-weight: bold;
        font-size: 2vw;
        border: 1px solid #747474;
        border-radius: 12px;
        box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
        margin-right: 3vw;
        margin-left: 2vw;
        padding-top: 3px;
        padding-bottom: 3px;
    }
 }

@media all  and (max-width: 370px){
    .photo{
        width: auto;
        height: 24vh;
       
        }
}
@media all and (min-width: 371px)  and (max-width: 380px){
    
    .photo{
     width: auto;
     height: 26vh;
    
     }
 }
 @media all  and (min-width: 381px)and (max-width: 500px){
    .photo{
        width: auto;
        height: 22vh;
       
        }
        
 }
@media all  and (max-width: 500px){
    
   .logo{
    width: auto;
    height: 8vh;
    }
    
    .searchBar{
        width: 90% !important;
        height: auto;
        font-size: small;
        padding: 1vh 2vw;
    }
    
    .searchBtn{
        width: auto;
        height: auto;
        background-color: #D68D2E;
        font-family: 'Quicksand';
        border: 0px solid white;
        padding: 1vh 2vw;
        font-size: small;
        color: white;
    }
}

@media all and (min-width: 501px) and (max-width: 800px){
    
    
    .logo{
     width: auto;
     height: 8vh;
     }
    
    .photo{
     width: auto;
     height: 35vh;
     }
 }
@media all and (min-width: 801px) and (max-width: 1000px){
    
    .logo{
     width: auto;
     height: 8vh;
     }
    .photo{
     width: auto;
     height: 33vh;
    
     }
 }
 @media all and (min-width: 1001px) and (max-width: 1200px){
    
    .logo{
     width: auto;
     height: 12vh;
     }
    .photo{
     width: auto;
     height: 78vh;
    
     }
 }
 @media all and (min-width: 1201px) and (max-width: 1300px){
    
    .logo{
     width: auto;
     height: 12vh;
     
     }
     .App-header{
        margin-right: 20vw;
     }
    .photo{
     width: auto;
     height: 62vh;
    
     }
 }