.App{
    overflow: hidden;
}

.App-header{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.logo{
    width: auto;
    height: 8vh;
    margin-right: 2vw;
    padding: 2vh 0;
    margin-left: 48%;   
}
.photo{
    width: auto;
    height: 100%;
    left: -170px;
    top: 226px;
    object-fit: cover;
}

.small-icon {
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    margin-right: 5px;
  }

/* .col-margin{
    margin-right: -40px;
} */

.backBtn{
    background: #D68D2E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 25px;
    color: white;
    border: 0px solid white;
    border-radius: 8px;
    width: 182px;
    height: auto;
    padding: 5px 0 10px;
    margin-top: 25px;
    margin-left: 50px;
}

.chinatown-photos{
    /* display: flex !important; */
    /* justify-content: center !important; */
    /* align-items: center !important; */
    text-align: center;
    margin-top: 4vh;
    height:auto;
    /* width:200px; */
    /* margin-left: 85px; */
}

h2.elevenPh{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand';
    margin-top: 4vh;
    font-weight: 700;
    font-size: 1.2rem !important;
    text-align:center;
}
.storeName{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand';
    margin-top: 4vh;
    font-weight: 700;
    font-size: 1.2rem !important;
    text-align:center;
}
.info{
    display: flex ;
    justify-content: center;
    align-items: baseline;
    margin-top: 4vh;
}
.info-details{
    display: flex ;
    margin-top: 4vh;
}
p{
    font-size: 15px;
}
.row img{
    /* width: 100%; */
    aspect-ratio: 3/2;
    object-fit: contain;
}


.contactStore-tel{ 
    margin-left: none;
    margin-right: none;
}

.timeStore{
    margin-right: none;
}

.mail-box{
    /* height: 100px; */
    width: 35px;
    height: 35px;
    /* margin-left: 150px; */
    /* margin-right: -20px; */
}

.store{
    /* display: flex; */
    margin-top: 10px;
    /* justify-content: left; */
    
}

.contactStore-tel-href{
    text-decoration: none;
    color: black;
}

.contactStore-tel-href:hover{
    color: rgb(78, 78, 78);
}

.store span{
    font-family: 'Poppins';
    font-style: normal;
    color: #747474;
    font-size: medium;
    letter-spacing: 0.02em;
}

.descStore p{
    font-family: 'Poppins';
    font-style: normal;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vw;
    padding-right: 5vw;
    padding-bottom: 1vh;
}

/* AMNETIES */
h3.amnetiesStore{
    margin-left: 0px;
    /* margin-top: 35px; */
    margin-bottom: 1vw;
    font-family: 'Quicksand';
    font-size: 30px;
    line-height: 20px;
    font-weight: 700;
}

.amneties-row {
    display: flex;
  }

.amneties-col{
    /* display: inline-block;
    margin-bottom: 1vw; */
    flex-basis: 30%;
    max-width: 30%;
    padding: 10px;
    text-align: center;
}

.amneties-name{
    font-family: 'Poppins';
    font-style: normal;
    color:black;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.02em;
}
/* GALLERY */
.galleryStore{
    font-family: 'Quicksand';
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}

.galleryRow{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.store-row span{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #747474;
}
.seven-img img{
    height: auto;
    width: 30vw;
    
} 
.seven-img{
    flex: 50%;
    padding: 0 5px 5vw;
} 

.row{
    display: flex;
    flex-wrap: wrap;
    padding: 0 5vw 0 5vw;
}

.carousel-container {
    width: 75% !important;
}

/* .timeStore-wrapper{
    justify-content: baseline;
    align-items: center;
    display: inline-block;
} */
@media all  and (min-width: 200px) and (max-width: 300px){
    .galleryStore {
        font-size: 20px;
    }
    .chnImg {
        width: 100% !important;
    }
    .timeStore{
        margin-left: 5vw !important;
        width: 20px;
        height: 20px;
    }
    .contactStore-tel{
        margin-left: 5vw !important;
        width: 20px;
        height: 20px;
    }
    .mail-box{
        margin-left: 5vw !important;
        width: 20px;
        height: 20px;
    }
    .checkStore{
        margin-left: -40px;
    }
    .store-row span{
        padding-right: 1vw;
        font-size: small;
    }
    .info-time{
        padding-left: 1vw !important;
        font-size: 10px !important;
    }
    .info-contact{
        padding-left: 1vw !important;
        font-size: 10px !important;
    }
    .info-email{
        padding-left: 1vw !important;
        font-size: 10px !important;
    }
    .small-icon {
        width: 12px;
        height: 12px;
    }
    .amneties-name {
        font-size: 10px !important;
    }
    .col-margin {
        margin-left: 20vw !important;
        align-items: center !important;
    }
}
@media all and (min-width: 301px) and (max-width: 500px){   
    .galleryStore {
        font-size: 20px;
    }
    .timeStore{
        margin-left: 11vw;
    }
    .contactStore-tel{
        margin-left: 11vw;
    }
    .mail-box{
        margin-left: 11vw
    }
    .contactStore{
        margin-left: 11vw;
    }
    .checkStore{
        margin-left: -40px;
    }
    .store-row span{
        padding-right: 1vw;
    }

    h3.amnetiesStore{
       margin-bottom: 3vh;
    }
    .amneties-col p{
        padding-left: 3vw;
        margin-left: 10vw;
    }
    .info-time{
        padding-left: 3vw !important;
        font-size: 13px;
    }
    .info-contact{
        padding-left: 3vw !important;
        font-size: 13px !important;
    }
    .info-email{
        padding-left: 2vw !important;
        font-size: 13px;
    }
    /* .info{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    } */
    .small-icon{
        width: 12px;
        height: 12px;
    }
    .amneties-name{
        font-size: 13px;
    }
    .col-margin{
        width: 50% !important;
    }
    .chnImg{
        width: 100% !important;
    }
}
@media all and (min-width: 501px) and (max-width: 750px){
    .timeStore{
        margin-left: 25vw;
    }
    .contactStore{
        margin-left: 35vw;
    }
    .mail{
        margin-left: 35vw;
    }
    .mail p{
        margin-left: 35vw;
    }
    .col-margin{
        margin-top: 8px !important;
        width: 50% !important;
    }
}
@media all  and (max-width: 750px){
    .store-row span{
        padding: 0;
        margin: 0;
        padding-left: 25px;
        font-size: medium;
        /* padding-right: 9vw; */
    }
}
@media all and (min-width: 601px) and (max-width: 800px){
    .timeStore{
        margin-left: 35vw;
    }
    .contactStore{
        margin-left: 35vw;
    }
}
@media all  and (min-width: 801px) and (max-width: 850px){
    /* .timeStore{
        margin-left: 11vw;
    } */
    /* .contactStore{
        margin-left: 11vw;
    } */
    .mail{
        margin-right: 30vw;
    }
    .checkStore{
        margin-left: -170px;
        margin-right: -100px;
    }
    .store-row span{
        padding-right: 1vw;
    }
    .info-time{
        padding-left: 5vw;
        font-size: small;
        justify-content: center;
    }
    .info-contact{
        padding-left: 5vw;
    }
    .info-email{
        padding-left: 5vw;
        font-size: 8px;
    }
    .info{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    }
}
@media all  and (max-width: 800px){
   
    .backBtn{
        font-size: 4vw;
        color: white;
        border: 0px solid white;
        border-radius: 8px;
        width: 30vw;
        height: auto;
        padding: 2% 2%;
        margin-top: 4vh;
        margin-left: 2vw;
    }
    /* .sevenElevenLogo{
        margin-left: 30vw;
        margin-top: 2vh;
    }
    h2.elevenPh{
        margin-left: 20vw;
        margin-top: 2vh;
    } */
    /* .info{
        display: inline-block;
        justify-content: center;
        align-items: baseline;
    } */
    /* .store-row{
        padding-right: 12vw;
    } */

    .store-row span{
        padding: 0;
        margin: 0;
        padding-left: 25px;
        font-size: small;
        /* padding-right: 9vw; */
    }
    .descStore p{
        font-size: 4vw;
        line-height: 8vw;
        margin: 5% 8%; 
    }
    h3.amnetiesStore{
        /* margin-left: 25vw;
        margin-top: 8vh; */
        text-align: center;
    }
    .amnetiesCheck{
        margin-left: 25vw;
    }
    .amneties-col p{
        padding-left: 3vw;
    }
    .seven-img img{
        height: auto;
        width: 70vw;
        margin-bottom: 3vh;
        margin-left: 1vw;
    } 
    h4.amnetiesStore{
        margin-left: 35vw;
        margin-top: 8vh;
        margin-bottom: 5vh;
    }
    .timeStore{
        margin-left: 11vw;
    }
    .contactStore-tel{
        margin-left: 11vw;
    }
    .mail-box{
        margin-left: 11vw;
    }
    .checkStore{
        margin-left: -40px;
    }
    .store-row span{
        padding-right: 1vw;
    }
    .info-time{
        padding-left: 5vw;
        font-size: 13px;
        justify-content: center;
    }
    .info-contact{
        padding-left: 5vw;
        font-size: 13px;
        justify-content: center;
    } 
    .info-email{
        padding-left: 4vw;
        font-size: 13px;
        justify-content: center;
    }
    .col-margin{
        padding-left: 5vw !important; /*to center amneties*/
        text-overflow: ellipsis;
    }
}
 @media all and (min-width: 900px) and (max-width: 950px){
    .chnImg {
        width: 100% !important;
    }
    /* .timeStore{
        margin-left: 11vw;
    }
    .contactStore{
        margin-left: 11vw;
    }
    .mail{
        margin-left: 11vw;
    } */
    .checkStore{
        margin-left: -40px;
    }
    .store-row span{
        padding-right: 1vw;
    }
    .info-time{
        /* padding-left: 5vw; */
        font-size: 13px;
        justify-content: center;
    }
    .info-email{
        /* padding-left: 5vw; */
        font-size: 13px;
    }
    .info-contact{
        /* padding-left: 5vw; */
        font-size: 13px;
    }
    .info{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    }
    .row{
        margin-top: 50px !important;
        padding: 0 !important ;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25% !important;
        padding: 0 !important;
    }
    .storeName{
        margin-top: 0 !important;
    }
    .galleryStore{
        font-size: 20px;
    }
}
@media all and (min-width: 1000px) and (max-width: 1200px){
    .timeStore{
        margin-left: 5vw;
    }
    .contactStore{
        margin-left: 6vw;
    }
    .mail{
        margin-left: 5vw;
    }
    .checkStore{
        margin-left: -40px;
    }
    .store-row span{
        padding-right: 1vw;
    }
    .info-time{
        padding-left: 2vw;
        font-size: 8px;
        justify-content: center;
    }
    .info-email{
        padding-left: 2vw;
        font-size: 8px;
    }
    .info-contact{
        padding-left: 2vw;
        font-size: 8px;
    }
    .info{
        display: flex;
        align-items: center;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    .row img{
        vertical-align: middle;
    }
    .chinatown-photos{
        margin-left: 0px !important;
    }
}
 @media all  and (min-width: 1200px) and (min-width:1300px){
    .timeStore{
        margin-left: 0vw;
    }
    .contactStore{
        margin-left: 5vw;
    }
    .mail{
        margin-left: 5vw;
    }
    .checkStore{
        margin-left: -170px;
        height: 20px;
    }
    .store-row span{
        padding-right: 1vw;
    }
    .info-time{
        padding-left: 1vw;
        font-size: small;
        justify-content: center;
    }
    .info-contact{
        padding-left: 1vw;
        font-size: small;
        justify-content: center;
    }
    .info-email{
        padding-left: 10px; 
        font-size: small ;
        justify-content: center;
    }
    .info{
        display: flex;
        align-items: center;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        font-family: 'Poppins';
        font-style: normal;
        color: #747474;
        font-size: medium;
        letter-spacing: 0.02em;
    }
}