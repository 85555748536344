.footerContainer{
    max-width: 100%;
    overflow: hidden;
    margin-top: 10vh;
}
.footerHeader{
    background-color: #F4F4F4;
    display: flex;
    font-family: 'Fredoka';
    width: 100vw;
}
.footerLogoContainer{
    margin-left: 6vw;
   
}
.footerTitleContainer{
    margin-top: 2vh;
    width: fit-content;
    margin-right: 20vw;
    margin-left: 20vw;
    
}
.footerTitleContainer p{
    font-size: 20px;
    font-family: 'Quicksand';
    font-weight: bolder;
    margin-left: 0;
    color: black;
}
.footerLogo{
    width: auto;
    height: 80%;
    margin-right: 2vw;
    padding: 2vh 0;
    
}
@media all and (max-width: 300px){
    .footerHeader{
        display: -webkit-inline-box;
    }
    .footerLogo{
        width: auto;
        height: 7vh;
        margin-right: 2vw;
        padding: 2vh 0;
        
    }
    .footerTitleContainer{
        margin-top: 2vh;
        width: fit-content;
        margin-right: 6vw;
        margin-left: 0;
        
    }
    .footerTitleContainer p{
        font-size: 2vw;
        font-family: 'Quicksand';
        font-weight: bolder;
        margin: 0;
        padding: 0;
        margin-left: 4vw;
    }
 }
@media all and (min-width: 301px) and (max-width: 700px){
    .footerHeader{
        display: -webkit-inline-box;
    }
    .footerLogo{
        width: auto;
        height: 7vh;
        margin-right: 4vw;
        padding: 2vh 0;
        
    }
    .footerTitleContainer{
        margin-top: 2vh;
        width: fit-content;
        margin-right: 7vw;
        margin-left: 0;
        
    }
    .footerTitleContainer p{
        font-size: 2vw;
        font-family: 'Quicksand';
        font-weight: bolder;
        margin-right: auto;
    }
 }

  @media all and (min-width: 701px) and (max-width: 1000px){
    .footerHeader{
        display: -webkit-inline-box;
        overflow: scroll;
    }
    .footerLogo{
        width: auto;
        height: 7vh;
        margin-right: 2vw;
        padding: 2vh 0;
        
    }
    .footerTitleContainer{
        margin-top: 2vh;
        width: fit-content;
        margin-right: 15vw;
        margin-left: 0;
        
    }
    .footerTitleContainer p{
        font-size: 2vw;
        font-family: 'Quicksand';
        font-weight: bolder;
        margin-left: 4vh;
    }
    
  }
  
  @media screen  and (min-width: 1001px) and (max-width: 1200px){
    
    .footerTitleContainer{
        margin-top: 2vh;
        width: fit-content;
        margin-right: 15vw;
        margin-left: 0;
        
    }
    .footerTitleContainer p{
        font-size: 2vw;
        font-family: 'Quicksand';
        font-weight: bolder;
        margin-left: 4vh;
    }
    
  }
  
  @media screen  and (min-width: 1201px) and (max-width: 1300px){
    
    .footerTitleContainer{
        margin-top: 2vh;
        width: fit-content;
        margin-right: 15vw;
        margin-left: 0;
        
    }
    .footerTitleContainer p{
        font-size: 2vw;
        font-family: 'Quicksand';
        font-weight: bolder;
        margin-left: 5vh;
    }
    
  }
  
  