.App{
    overflow: hidden;
    background-image: url('../../Assets/background.png');
    
}
.logo-header{
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4vh;
}
.logo{
      width: auto;
      height: 13vh;
      padding: 2vh 0;
}
.photo{
    width: 100% !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-photos{
    margin-top: 75px;
    margin-bottom: -100px;
    aspect-ratio: 16/9;
    object-fit: contain;    
}
@media all  and (max-width: 370px){
    .photo{
        width: auto;
        height: 50vh;
       
        }
}
@media all and (min-width: 371px)  and (max-width: 380px){
    
    .photo{
     width: auto;
     height: 50vh;
    
     }
 }
 @media all  and (min-width: 381px)and (max-width: 500px){
    .photo{
        width: auto;
        height: 50vh;
       
        }
 }
@media all  and (max-width: 500px){
   .logo{
    width: auto;
    height: 8vh;
    }
   .header-photos{
    width: 100%;
   }
   .d-block{
    height: 70vw;
   }
}

@media all and (min-width: 501px) and (max-width: 800px){
    
    
    .logo{
     width: auto;
     height: 8vh;
     }
    
    .photo{
     width: auto;
     height: 35vh;
     }
 }
@media all and (min-width: 801px) and (max-width: 1000px){
    
    .logo{
     width: auto;
     height: 8vh;
     }
    .photo{
     width: auto;
     height: 33vh;
    
     }
 }
 @media all and (min-width: 1001px) and (max-width: 1200px){
    
    .logo{
     width: auto;
     height: 12vh;
     }
    .photo{
     width: auto;
     height: 78vh;
    
     }
 }
 @media all and (min-width: 1201px) and (max-width: 1300px){
    
    .logo{
     width: auto;
     height: 12vh;
     
     }
    .photo{
     width: auto;
     height: 75vh;
    
     }
 }